export default {
  a: `
 az
aazz
a  z
d  c
ddcc
d  c
`,
  b: `
bbqq
b  q
booq
roov
r  v
rrvv
`,
  c: `
ccc
x c
x
x
x q
qqq
`,
  d: `
ddcc
dd c
qq c
qa r
qa r
aarr
`,
  e: `
eee
e
cc
cc
e
eee
`,
  f: `
crrr
c  r
cc
dd
d
d
`,
  g: `
iooo
i  o
i
i dd
c  d
cccd
`,
  h: `
l  h
l  h
llhh
sscc
s  c
s  c
`,
  i: `
pp
pp
dc
dc
dc
dc
`,
  j: `
 ooo
   o
   p
   p
c  p
cccp
`,
  k: `
t  c
t cc
ttc
ssd
s dd
s  d
`,
  l: `
to
to
to
to
attt
aaat
`,
  m: `
s  r
ssrr
csrc
c  c
c  c
c  c
`,
  n: `
o  r
oo r
xo r
x pr
x pp
x  p
`,
  o: `
ookk
o  k
o  k
s  t
s  t
sstt
`,
  p: `
pppr
p  r
o rr
o
o
o
`,
  q: `
ddds
d  s
s  s
s  s
s cc
s cc
`,
  r: `
ddds
d  s
s  s
s cs
s cc
s  c
`,
  s: `
abbb
aa b
 a
  d
a dd
aaad
`,
  t: `
sscc
sscc
 fo
 fo
 fo
 fo
`,
  u: `
q  q
q  q
q  q
q  q
pppp
ssss
`,
  v: `
c  c
c  c
c  c
c  c
 xx
 xx
  `,
  w: `
c  c  c
c  c  c
c  c  c
c  c  c
 aa aa
 aa aa
  `,
  z: `
zzzs
z ss
  s
 a
aa c
accc
`,
  x: `
x  c
xxcc
 xc
 pr
pprr
p  r
`,
  y: `
c  r
ccrr
 cr
 oo
 oo
`,
}
