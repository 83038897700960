export default {
  č: `
   oo
   oo
xxxxyyyy
  ssoo
  ssoo
  qqzz
  qqzz
  t  t
  t  t
  tt tt
`,
  š: `
   ccc
  ffcdd
 ffzzzdd
   qzx
   q x
   q x
   q x
`,
  ž: `
aa         o o
 aa  zzzz fdaa
  xxx    ff  aa
   x      fzzzz
   f     d
 apffzzzzoa 
aapf    a aa
a p     aa a
  p      a
`,
  ķ: `
rr    
 r   kk
 rgggkk
 ppgpp
 p   p
 p   p
`,
  ģ: `
 x   q
xxx qqq
ppdddpp
 ppdpp
  ccc
   c
`,
  //   ģ: `
  // a      e p
  // affffueep
  // aattguue pp
  // ctttyouwwww
  // cnyyyor
  // cn   or
  // cnn  orr
  // `,
  ".": `
xx
xx
`
};
